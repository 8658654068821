/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

@import url('https://fonts.googleapis.com/css?family=Oxygen');

*{
    font-family: Oxygen;
}

.test-border {
    border: 5px solid red;
}

.fill {
    min-height: 100%;
    height: 100%
}

/*---Buttons and Navbar---*/
button.grow_box::after {
    -webkit-transition: all 0.35s;
    -o-transition: all 0.35s;
    transition: all 0.35s;
    left: 0;
    right: 0;
    top: -100%;
    bottom: 100%;
    content: "";
    background: #5C6B73;
    position: absolute;
    z-index: -1;
    display: block;
}

button.grow_box:hover::after {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

button.grow_box {
    width:75px;
    background: #253237;
    color: #C2DFE3;
    border: none;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

button.selected_grow_box {
    width:75px;
    background: #5C6B73;
    color: #C2DFE3;
    border: none;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.navbar-position {
    position: fixed;
    width:100%;
}

.media-icon {
    width:24px;
    height: 24px;
}

.media-position {
    position:fixed;
    bottom:24px;
    right:25px;
}

/*---Skills section---*/

.skill-wrapper {
    display: none;
    min-width: 120px;
    max-width: 120px;
    height: 110px;
    padding: 5px;
    margin: 15px;
    border: 3px solid #5c6b73
}

.skill-icon {
    width: 28px;
    height: 28px;
    margin-bottom:10px;
}

@media screen and (max-width: 460px) {
    .skill-wrapper{
        min-width: 90px;
        max-width: 90px;
        height: 90px;
        margin: 5px;
    }
}

@media screen and (max-width: 350px), (max-height: 400px) {
    .skill-wrapper{
        min-width: 80px;
        max-width: 80px;
        height: 90px;
        margin: 5px;
    }
}

/*---About section---*/

.portrait {
 width:100;
 height:175px;
}

/*---Main Page Background Styles---*/
.primary-background {
    background-color: #253237;
}

.primary-border {
    border: 3px solid #5C6B73
}

/*---Text---*/

a {
    color: #c2dfe3;
}

a:visited {
    color: #9db4c0;
}

a:hover {
    color: #9db4c0;
}

h1 {
    color: #e0fbfc;
    font-weight: bold;
    text-align: center;
}

h2 {
    color: #c2dfe3;
    text-align: center;
}

h3 {
    color: #c2dfe3;
}

h4 {
    color: #c2dfe3;
    font-size: 22px;
    text-align: center;
}

.name-mark {
    color: #9DB4C0;
    position:fixed;
    bottom:20px;
    left:25px;
}

.icons-link {
    color: #5C6B73;
    text-align: center;
}

.icons-link:hover {
    color: #5C6B73

}

.icons-link:visited {
    color: #5C6B73

}

@media screen and (max-width: 460px), (max-height: 400px) {
    h1 {
      font-size: 1.6em;  
    }

    h2 {
      font-size: 1.1em;
    }

    h3 {
      font-size: 0.9em;
    }

    h4 {
      font-size: 16px;
    }
}

/*---Page Layout---*/

.main-content-wrapper {
    width:50%;
}

.page-margin {
    margin: 17px;
}

@media screen and (max-width: 1085px), (max-height: 500px) {
    .main-content-wrapper{
        width:100%;
        padding: 0px;
        margin: 0px;
    }
}


@media screen and (max-width: 460px), (max-height: 460px) {
    .page-margin{
        margin:0px;
    }
}